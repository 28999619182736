<template>
  <div class="schedule-table-cell" :class="{ '__has-work-shift': workShift?.id }" :style="cellStyles">
    <div
      class="schedule-table-cell__item"
      ref="cellElem"
      :class="{
        '__has-work-shift': workShift?.id,
        '__my-work-shift': isMyWorkShift,
        __empty: workShift?.id && !workShift.employee,
        __disabled: !canAddWorkShift && !workShift?.id,
      }"
      :style="{ background: workShift?.highlight }"
      @click.left="
        e => $emit('handleClickCell', e, workShift, workShift.date, indexes.rowIndex, indexes.workShiftIndex)
      "
      @click.right.prevent="$emit('handleRightClickCell', workShift)"
    >
      <template v-if="workShift?.id">
        <template v-if="workShift.employee">
          <div class="schedule-table-cell__info">
            <span class="schedule-table-cell__item-text screen-name">
              {{ workShift.employee.nickname }}
            </span>
            <div class="schedule-table-cell__additional-info __left">
              <base-tag v-if="workShift?.turnOrder === 0" class="hidden" />
              <span class="job-icon hidden">{{ workShift?.job?.job_icon }}</span>
            </div>
          </div>
          <div class="schedule-table-cell__additional-info __right">
            <span v-if="workShift.timeAdd" class="schedule-table-cell__item-text add-hours hidden">
              +{{ workShift.timeAdd }}ч
            </span>
            <base-icon
              v-if="!isMobile && user?.roles?.includes('admin')"
              class="schedule-table-cell__icon hidden"
              path="menu-trigger.svg"
            />
          </div>
        </template>
        <div v-else class="d-flex">
          <span class="schedule-table-cell__item-text empty-schedule-text">Свободная смена</span>
          <span class="schedule-table-cell__item-text empty-schedule-time">({{ formattedSumTime }})</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import BaseTag from "@/components/BaseTag/BaseTag.vue";
import BaseIcon from "@/components/BaseIcon/BaseIcon.vue";
import { computed, onMounted, onUpdated, ref } from "vue";
import { DEVICE_TYPES } from "@/lib/helpers/checkDevice";
import { useStore } from "vue2-helpers/vuex";

const props = defineProps({
  /**
   * @type {WorkShift}
   * */
  workShift: Object,
  indexes: Object,
  hasPart: Boolean | Number,
  canAddWorkShift: Boolean,
});

const cellElem = ref(null);
const store = useStore();
const user = computed(() => store.state.user);
const isMobile = computed(() => store?.state?.breakPoint === DEVICE_TYPES.MOBILE);
const isMyWorkShift = computed(() => store?.state?.user?.uid === props.workShift?.employee?.id);
let totalWidthCell = 32 + 24 + 12 + 28;
const priority = [
  // { name: "screenName", className: ".schedule-table-cell__item-text.screen-name" },
  { name: "settingIcon", className: ".schedule-table-cell__icon" },
  { name: "hoursAdd", className: ".add-hours" },
  { name: "jobIcon", className: ".job-icon" },
  { name: "tag", className: ".base-tag" },
];

const formattedSumTime = computed(() => {
  const diffHours = props.workShift.hourEnd - props.workShift.hourBegin;
  const diffMinutes = props.workShift.minuteEnd - props.workShift.minuteBegin;

  const finishHour = diffMinutes >= 0 ? diffHours : diffHours - 1;
  const finishMinute = diffMinutes < 0 ? 60 + diffMinutes : diffMinutes;

  return `${finishHour}ч` + (finishMinute === 0 ? "" : ` ${finishMinute}м`);
});

const shouldShowEmptyText = () => {
  const childElem = cellElem.value?.querySelector(".empty-schedule-text");
  if (childElem) {
    childElem.classList.remove("hidden");
    if (24 + childElem.offsetWidth <= cellElem.value?.offsetWidth) {
      totalWidthCell += childElem.offsetWidth;
    } else {
      childElem.classList.add("hidden");
    }
  }
};

const shouldShow = () => {
  for (let i = 0; i < priority.length; i++) {
    const childElem = cellElem.value?.querySelector(priority[i].className);

    if (childElem) {
      childElem.classList.remove("hidden");
      if (totalWidthCell + childElem.offsetWidth <= cellElem.value?.offsetWidth) {
        totalWidthCell += childElem.offsetWidth;
      } else {
        childElem.classList.add("hidden");
        break;
      }
    }
  }
};

const cellStyles = computed(() => {
  return {
    gridColumn: `span ${(props.workShift.hourEnd -
      props.workShift.hourBegin +
      (props.workShift.minuteEnd - props.workShift.minuteBegin) / 60) *
      4}`,
  };
});

onMounted(() => {
  shouldShow();
  shouldShowEmptyText();
});

onUpdated(() => {
  shouldShow();
  shouldShowEmptyText();
});
</script>

<style scoped lang="scss">
@import "@/styles/ui/fontSize";

.schedule-table-cell {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  background: var(--Primary-light-bg, #eef5fc);
  @include add-font-face("body-2");

  &__info {
    display: flex;
    gap: var(--sp-6);
    align-items: center;
    overflow: hidden;
  }

  &__additional-info {
    display: flex;
    align-items: center;

    &.__left {
      gap: var(--sp-3);
    }

    &.__right {
      gap: var(--sp-7);
    }
  }

  &__item {
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px;
    cursor: pointer;

    &.__date {
      height: 100%;
      justify-content: flex-start;
      padding: var(--sp-2) 0;
    }

    &.__header {
      background: var(--Secondary-Bg, #ffd596);
      justify-content: center;
      cursor: default;
    }

    &.__disabled {
      cursor: initial;
    }

    &.__has-work-shift {
      background: var(--Primary-bg, #dbedff);
    }

    &.__my-work-shift {
      background: var(--color-primary-light-main, #0053ad);

      .schedule-table-cell__item-text {
        color: var(--Basic-White, #fff);
      }
    }

    &.__empty {
      background: var(--Red-Light-bg, #ffe7ef);
      justify-content: center;

      .schedule-table-cell__item-text {
        color: var(--Red-Dark-main, #ad0015);
      }
    }

    &.__selected {
      background: var(--Primary-light-Main, #4196f0);
    }
  }

  &__item-text {
    display: block;
    width: 100%;
    height: 100%;
    color: var(--Primary-Dark-main, #0053ad);
    //overflow: hidden;
    white-space: nowrap;
    //text-overflow: ellipsis;
  }

  @include mobile-only {
    &__item {
      padding: var(--sp-ex-6) var(--sp-2);
    }
    &__item-text {
      @include add-font-face("body-3");
    }
  }
}

.d-flex {
  display: flex;
  gap: 12px;
  align-items: center;
}
</style>
